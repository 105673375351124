import "../../assets/css/mentor.new.css";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as mentorActionSinda from "../../actions/mentorActionSinda";
import { showToasterWithOk } from "../../utils/helpers";
import { Container, Row, Col } from "react-bootstrap";
import Filter from "./filter";
import Search from "./search";
import Details from "./details";
import ReactPaginate from "react-paginate";
import { setData } from "../../utils/helpers/localData";
const queryString = require("query-string");

const Index = (props) => {
  const { actions, title, mentorsData, isAuth, userInfo, programmes } = props;

  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState({});
  const location = useLocation();
  console.log("🚀 ~ location:", location?.pathname)

  const getMentor = async (e, mentorId) => {
    e.preventDefault();
    if (isAuth === false) {
      showToasterWithOk(
        "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
      );
      history.push(`/login`);
    } else {
      const userId = userInfo.id;
      const request = {};
      request.userId = userId;
      request.mentorId = mentorId;
      actions.saveSelectMentor(request);
    }
  };

  const getUserDetails = (
    e,
    full_name,
    image,
    organization,
    position,
    about,
    linkedin,
    main_area,
    mf_mental_health,
    mf_certified_coach
  ) => {
    e.preventDefault();
    setModalShow(true);
    setUserData({
      full_name: full_name,
      image: image,
      organization: organization,
      position: position,
      about: about,
      linkedin: linkedin,
      main_area: main_area,
      mf_mental_health: mf_mental_health,
      mf_certified_coach: mf_certified_coach,
    });
  };

  const setMentorWaitlistAvailability = (e, mentorId) => {
    e.preventDefault();
    const userId = userInfo.id;
    const request = {};
    request.userId = userId;
    request.mentorId = mentorId;
    actions.saveWaitlistMentor(request);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }
    if (params.get("domains")) {
      var domains = queryStringParsed["domains"];
      request.domains = domains;
    }

    if (params.get("is_available")) {
      var is_available = queryStringParsed["is_available"];
      request.is_available = is_available;
    }

    if (params.get("programmes")) {
      var programmes = queryStringParsed["programmes"];
      request.programmes = programmes;
    }
    if (params.get("mf_mental_health")) {
      var mf_mental_health = queryStringParsed["mf_mental_health"];
      request.mf_mental_health = mf_mental_health;
    }
    if (params.get("mf_certified_coach")) {
      var mf_certified_coach = queryStringParsed["mf_certified_coach"];
      request.mf_certified_coach = mf_certified_coach;
    }

    if (isAuth === true) {
      actions.loadData(request, userInfo.id, "Mentor", "");
    } else {
      actions.loadData(request, 0, "Mentor", "");
    }
    setViewPage(request.page ? request.page - 1 : 0);
    document.title =
      "vLookUp | Creating impactful connections for women mentees";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "vLookUp mentors are inspiring and empowering role models, have deep passion for growing talent and believe in fostering diversity."
      );
  }, [actions, title, location, userInfo, isAuth]);

  const history = useHistory();
  const resetPage = () => {
    history.push(location?.pathname);
    window.location.reload();
  };
  const searchData = (data) => {
    const params = new URLSearchParams(location.search);
    if (data.keyword) {
      params.set("keyword", data.keyword);
    }
    history.push({
      pathname: "/mentors",
      search: "?" + params,
    });
  };

  const handleFormClick = (item, action) => {
    actions.loadFormPop(action);
  };

  // console.log('pagination',pagination);

  useEffect(() => {
    actions.getAllProgrammes();
  }, []);
  const [viewpage, setViewPage] = useState(0);
  const handlePageClick = (data) => {
    setViewPage(data.selected);
    let page = data.selected + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: location?.pathname,
      search: "?" + params,
    });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const referringURL = document.referrer;
    const queryParams = new URLSearchParams(location.search);
    const isOlayUser = queryParams?.get('is_olay_user');

    if (isOlayUser === 'yes') {
      const referringHostname = 'ai-shu.in';
      const currentDate = new Date();
      const expirationDate = new Date(currentDate);
      expirationDate.setDate(currentDate.getDate() + 7);
      document.cookie = `redirectFrom=${referringHostname}; expires=${expirationDate.toUTCString()}; path=/`;
    }
  }, [])


  const handleProgram = () => {

    const baseUrl = `${window.location.protocol}//${window.location.host}/`;
    setData('program', 'ignite_women_mentoring')
    window.open(baseUrl + 'register', '_blank');
  }

const handleLogin = () =>{
  const baseUrl = `${window.location.protocol}//${window.location.host}/`;
  window.open(baseUrl + 'login', '_blank');
}

  return (
    <div >
      <section className='igniteSection'>
        <div className="banner-Cap-outer">
          <Container>
            <div className='banner-Cap'>

              <h1>Welcome to IGNITE <br />Professional Women <br />Network and Mentoring Program</h1>
              <div className='poweredBY'>
                <strong>Powered by</strong>
                <span className='d-inline-block'>
                  <a
                    href="https://www.vlookup.ai/"
                    target="_blank"
                  // className="toggle_btn d-lg-none"
                  >
                    <img src='assets/images/logoBanner.svg' alt='logo' />
                  </a>
                </span>
              </div>
              <div className="d-none d-lg-block">
             
                  <img src='assets/images/shapeIcon.svg' alt='shapeIcon' />
          
              </div>

            </div>
          </Container>
        </div>
        <div className='igniteBannerRow'>

          <div className='bannerShapeImg'>
            <img src='assets/images/bannerShapeImg.png' className="d-none d-sm-block" alt='banner' />
            <div className='bannerShapelogo'>
              {/* <strong>Championed by</strong> */}
              <a
                  href="https://www.ignite-women.com/"
                  target="_blank"
                >
              <img src='assets/images/comShape.png' alt='logo' />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="banner_fix">
        <Container>
          <div className="mentoring_banner_caption">
            <div className="banner_left_data">
              <h1>
                {/* <small>Welcome to</small> */}
      {/* <strong>Welcome to User STEM Mentoring Programme</strong> */}
      {/* <small>Mentoring Programme</small> */}
      {/* </h1>
              <div className="brand_logos">
                <div className="vlookup_logo">
                  <strong>Powered by</strong>
                  <Link to='/' target="_blank" >
                  <img src="../assets/images/white-vlookup.svg" />
                  </Link>
                </div>

                <div className="vlookup_logo">
                  <strong>Championed by</strong>
                  <a href='https://www.olayskincare.com/en-in/' target="_blank" className="olay">
                  <img src="../assets/images/white-olay.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="banner_right_pic">
            <a href='https://ai-shu.in/' target="_blank">
              <img src="../assets/images/banner_circle.png" alt='aishu' />
            </a>
          </div>
        </Container> */}
      {/* </section> */}

      <section className="outer_main mentoring_page">
        <Container className="page_heading text-center mb-5">
          <h1 style={{ fontSize: "22px", fontWeight: "600" }}>
          Unlock your potential as a successful woman leader with the support of the right mentors through IGNITE
            {/* Find Your Mentor ({props.pagination.total}){" "} */}
          </h1>
          <p className="email_Multi">Please reach out to <span>  <a
                       className="App-link"
                       href={"mailto:lea.dwiartanti@kearney.com"}
                      
                     >lea.dwiartanti@kearney.com</a></span> and <span><a
                     className="App-link"
                     href={"mailto:anisha.firaldi@kearney.com"}
                    
                   >anisha.firaldi@kearney.com</a></span> for any questions</p> 
          <div className="col-md-12 mt-3">
            <div className="text-center">
              {
                Object.keys(userInfo)?.length > 0 ? <Link to="/manage-profile" target="_blank" className="btn mr-2 mt-3" style={{ padding: '10px 40px' }} >
                  My Dashboard
                </Link> : <>

                <div onClick={()=>handleLogin()}
                    className="btn mr-2 mt-2 button-large-style">
                    Login
                  </div>

                  <div
                    onClick={() => handleProgram()}
                    className="btn mr-2 mt-2 button-large-style">
                    Register
                  </div>

                  <Link to={{
                    pathname: '/mentors',
                    state: 'ignite-women-mentoring',
                  }} target="_blank" className="btn mr-2 mt-2 button-large-style">
                    Browse all vLookUp Mentors
                  </Link></>
              }




            </div>
          </div>



        </Container>
        <div className="page_content">
          <Container>
            <div className="page_content_inner">
              <Search
                onClick={resetPage}
                onSearch={searchData}
                handleFormClick={handleFormClick}
                programmes={programmes}
              />
              <Row>
                <Col id="left_sidebar" md={4} lg={3}>
                  <Filter
                    onClick={resetPage}
                    onSearch={searchData}
                    handleFormClick={handleFormClick}
                  />
                </Col>

                {isAuth && (
                  <>
                    <Col md={8} lg={9}>
                      <Row className="mentor_list">
                        {mentorsData &&
                          mentorsData.map((item, key) => (
                            <Col xs={12} xl={4} lg={4} md={6} sm={6} key={key}>
                              <div className="outer position-relative">
                                <div className="mentor_img">
                                  {item.image && (
                                    <img
                                      src={item.image}
                                      alt="professional consultants for women"
                                    />
                                  )}
                                  {!item.image && (
                                    <img
                                      src={
                                        process.env
                                          .REACT_APP_STATIC_IMAGE_PATH +
                                        "mentor_women_two.png"
                                      }
                                      alt="professional consultants for women"
                                    />
                                  )}
                                </div>
                                <div className="mentor_info">
                                  <h3>{item.full_name}</h3>
                                  <h5>{item.position}</h5>
                                  <h5>{item.organization}</h5>
                                  <h5>{item.main_area}</h5>
                                </div>
                                <div className="mentor_btn">
                                  {" "}
                                  <div>
                                    {userInfo.role_id !== "Mentor" &&
                                      item.is_relationship === 0 &&
                                      item.is_mentor_terminate === 0 &&
                                      item.is_same_program === 1 && (
                                        <>
                                          {item.is_reached_limit === 0 &&
                                            item.user_availability === 1 ? (
                                            <Link
                                              to=""
                                              onClick={(e) => {
                                                getMentor(e, item.id);
                                              }}
                                            >
                                              Indicate as Preferred Mentor
                                            </Link>
                                          ) : (
                                            <Link
                                              to=""
                                              onClick={(e) => {
                                                setMentorWaitlistAvailability(
                                                  e,
                                                  item.id
                                                );
                                              }}
                                            >
                                              Waitlist for availability
                                            </Link>
                                          )}
                                        </>
                                      )}
                                    <Link
                                      to=""
                                      id={key}
                                      onClick={(e) => {
                                        getUserDetails(
                                          e,
                                          item.full_name,
                                          item.image,
                                          item.organization,
                                          item.position,
                                          item.about,
                                          item.linkedin,
                                          item.main_area,
                                          item.mf_mental_health,
                                          item.mf_certified_coach
                                        );
                                      }}
                                    >
                                      {"Read More"}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ))}
                      </Row>
                      {props.pagination.total_pages && (
                        <ReactPaginate
                          breakLabel={"..."}
                          previousLabel={"<"}
                          nextLabel={">"}
                          forcePage={viewpage}
                          pageCount={props.pagination.total_pages}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          onPageChange={(v) => handlePageClick(v)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      )}
                    </Col>
                  </>
                )}

                {!isAuth && (
                  <Col md={8} lg={9}>
                    <Row className="mentor_list">
                      {mentorsData &&
                        mentorsData.map((item, key) => (
                          <Col xs={12} xl={4} lg={4} md={6} sm={6} key={key}>
                            <div className="outer position-relative">
                              <div className="mentor_img">
                                {item.image && (
                                  <img
                                    src={item.image}
                                    alt="find a career mentor online"
                                  />
                                )}
                                {!item.image && (
                                  <img
                                    src={
                                      process.env.REACT_APP_STATIC_IMAGE_PATH +
                                      "mentor_women_two.png"
                                    }
                                    alt="find a career mentor online"
                                  />
                                )}
                              </div>
                              <div className="mentor_info">
                                <h3>{item.full_name}</h3>
                                <h6>{item.position}</h6>
                                <h5>{item.organization}</h5>
                                <h5>{item.main_area}</h5>
                              </div>
                              <div className="mentor_btn">
                                {" "}
                                <div>
                                  {userInfo.role_id !== "Mentor" && (
                                    <Link
                                      to=""
                                      onClick={(e) => {
                                        getMentor(e, item.id);
                                      }}
                                    >
                                      Indicate as Preferred Mentor
                                    </Link>
                                  )}
                                  <Link
                                    to=""
                                    id={key}
                                    onClick={(e) => {
                                      getUserDetails(
                                        e,
                                        item.full_name,
                                        item.image,
                                        item.organization,
                                        item.position,
                                        item.about,
                                        item.linkedin,
                                        item.main_area
                                      );
                                    }}
                                  >
                                    {"Read More"}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Row>
                    {props.pagination.total_pages && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        siblingCount={5}
                        forcePage={viewpage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={props.pagination.total_pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={(v) => handlePageClick(v)}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    )}
                  </Col>
                )}
              </Row>
            </div>
          </Container>
        </div>
      </section>
      {modalShow && (
        <Details
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    mentorsData: state.mentorsData,
    pagination: state.pagination,
    isAuth: state.isAuth,
    userInfo: state.userInfo,
    programmes: state.programmes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(mentorActionSinda), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
