import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import * as settingActions from "../../actions/settingActions";

const Index = (props) => {
  const { setting, isAuth, actions } = props;
  
  useEffect(() => {
    const fetchData = () => {
      actions.getSettingData();
    };
    fetchData();
  }, [actions]);
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footerleft text-md-left ">
                <Link className="logo" to="/" title="Home">
                  <img
                    src={process.env.REACT_APP_STATIC_IMAGE_PATH + "logo.svg"}
                    alt="professional consultants for women"
                    className="img-fluid"
                  />
                </Link>
                <p>{setting.about_me}</p>
              </div>
            </div>
            <div className="col-md-4 offset-md-1 pt-4 pt-md-0">
              <div className="footertop quick_box">
                <h3>Quick Links</h3>
                <ul className="quicklinks">
                  <li>
                    <Link className="logo" to="/" title="Home">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="logo"
                      to="/page/privacy-policy"
                      title="Privacy Policy"
                    >
                        Privacy policy
                    </Link>
                  </li>
                  <li>
                    <Link className="logo" to="/aboutus" title="About">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="logo"
                      to="/page/terms-and-conditions"
                      title="Terms and conditions"
                    >
                      Terms and conditions
                    </Link>
                  </li>
                  <li>
                    <Link className="logo" to="/login" title="Login">
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link className="logo" to="/faq" title="FAQ">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link className="logo" to="/register" title="Sign Up">
                      Sign Up
                    </Link>
                  </li>
                  <li>
                    <Link className="logo" to="/contact-us" title="Contact Us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 pt-4 pt-md-0">
              <div className="footertop ml-md-auto">
                <h3>Connect With Us</h3>
                <ul className="sociallink">
                  <li>
                    <a
                      className="App-link"
                      href={setting.facebook_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" title='Facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href={setting.twitter_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <i className="fab fa-twitter" title='Twitter'></i> */}
                      <img className="withouthoverShow" src="assets/images/twiter_x.svg" width={23} height={23} title='Twitter'/>
                      <img className="withhoverShow"  src="assets/images/twiter_xhover.svg" width={23} height={23} title='Twitter'/>
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href={setting.linkedin_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin" title='Linkedin'></i>
                    </a>
                  </li>

                  <li>
                    <a
                      className="App-link"
                      href={setting.youtube_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-youtube" title='Youtube'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href={setting.instagram_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram" title='Instagram'></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="App-link"
                      href={setting.spotify_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-spotify" title='Spotify'></i>
                    </a>
                  </li>
                </ul>
               
              </div>
              <div className="footertop ml-md-auto" style={{margin:`9px`}}>
               
                <p> <i className="far fa-envelope"></i> &nbsp; <a
                       className="App-link"
                       href={"mailto:" +setting.support_email}
                      
                     >
                     {setting.support_email}
                     </a>
                     </p>

                     
{/* {location?.pathname?.includes('ignite-women-mentoring') && <p className="" ><i className="far fa-envelope"></i> &nbsp;  Please reach out to lea.dwiartanti@kearney.com and anisha.firaldi@kearney.com for any questions</p>} */}
                  </div>

            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <p> {setting.copy_right}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    isAuth: state.isAuth,
    setting: state.setting,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions, settingActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
