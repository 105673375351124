import React, { useState, useEffect, Fragment, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as menteesRequestAction from "../../actions/menteesRequestAction";
import * as baseActions from "../../actions/baseActions";
import { Col } from "react-bootstrap";
import UserSidebar from "../../Component/UserSidebar";
import Details from "./details";
import { Dropdown } from "react-bootstrap";
import Relationship from "./relationship";
import { UCWords } from "../../utils/helpers";
import Slider from "react-slick";
import firebase from "../../Firebase";
import DToolkit from "./dtoolkit";
import Moment from "moment";
import Feedback from "../MenteesLog/Feedback";
import FeedbackMentor from "./FeedbackMentor";
import ReportUser from "../Forum/reportUser";
import { Tooltip } from "@material-ui/core";
import config from "../../config";
import rainbow from '../../assets/images/rainbow.png'
import ScheduleMeetingModal from "../MentorDashboard1/scheduleMeetingModal";

const queryString = require("query-string");

const Index = (props) => {
  const { title, actions, menteeRequestData, userInfo, pagination } = props;
  const [room, setRoom] = useState({ roomname: "" });
  const usersDatabase = firebase.database().ref("users/");
  const [userData, setUserData] = useState({});
  const [endRelationship, setEndRelationship] = useState({});

  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  var Mentors_details = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    unslick: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          unslick: false,
        },
      },
    ],
  };

  const writeReview = (e, mentee_id, mentor_id, review) => {
    e.preventDefault();
    setModalShow(true);
    setUserData({
      mentee_id: mentee_id,
      mentor_id: mentor_id,
      mentee_review: review,
    });
  };

  const endRelationshipModal = (e, mentee_id, mentor_id, review, actionid) => {
    e.preventDefault();
    setRelationModalShow(true);
    setEndRelationship({
      mentee_id: mentee_id,
      mentor_id: mentor_id,
      mentor_review: review,
      actionid: actionid,
    });
  };

  const [modalShow, setModalShow] = useState(false);
  const [relationModalShow, setRelationModalShow] = useState(false);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    const request = {};
    if (params.get("sort")) {
      var sort = queryStringParsed["sort"];
      var direction = queryStringParsed["direction"];
      request.sort = sort;
      request.direction = direction;
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params.get("keyword")) {
      var keyword = queryStringParsed["keyword"];
      request.keyword = keyword;
    }
    document.title = title;
    actions.myMentors(request, userInfo.id);
  }, [title, actions, location, userInfo]);

  const downloadResume = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  const [toolkitShow, setToolkitModalShow] = useState(false);

  useEffect(() => {
    if (room.roomname) {
      history.push("/chat?roomKey=" + room.roomname);
    }
  }, [room, history]);

  const handleclickChat = (item) => {
    var roomUser;
    if (item.mentor_id > item.mentee_id) {
      roomUser = item.mentor_id + "_" + item.mentee_id;
    } else {
      roomUser = item.mentee_id + "_" + item.mentor_id;
    }

    // const roomUser = item.mentor_id + "_" + item.mentee_id;
    let time = Moment.utc().valueOf();

    const message = {};
    message.mentor_name = item.mentor_name;
    message.mentor_image = item.mentors_image;
    message.mentor_id = item.mentor_id;
    message.mentee_name = userInfo.first_name + " " + userInfo.last_name;
    message.mentee_image = userInfo.image;
    message.mentee_id = userInfo.id;
    message.role_id = userInfo.role_id;
    message.device_type = "Web";
    message.message = "";
    message.key = roomUser;
    message.status = 0;
    message.time = Moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    message.epoch = -1 * time;

    setRoom({ ...room, roomname: roomUser });

    let mentorDatabaseKey = "users/" + item.mentor_id + "/" + roomUser;
    usersDatabase.equalTo(mentorDatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(mentorDatabaseKey);
        newUser.set(message);
      }
    });

    let menteedatabaseKey = "users/" + item.mentee_id + "/" + roomUser;
    usersDatabase.equalTo(menteedatabaseKey).once("value", (snapshot) => {
      if (snapshot.exists()) {
      } else {
        const newUser = firebase.database().ref(menteedatabaseKey);
        newUser.set(message);
      }
    });
  };
  const [modeldata, setModelData] = useState("");
  const [model, setModel] = useState(false);
  const [userData2, setUserData2] = useState({});
  const [reportModalShow, setReportModalShow] = useState(false);
  const viewReportModal = (e, item) => {
    e.preventDefault();
    setReportModalShow(true);
    setUserData2({ item: item, from: "user" });
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </span>
  ));

  const [scheduleModal, setScheduleModal] = useState(false);
  const [scheduleUser,setScheduleUser] = useState({})
  const handleSchedule = (e,item) => {
    e.preventDefault();
    setScheduleModal(true)
    setScheduleUser(item)
  }
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h1 className="dash_heading">My Mentor log</h1>
          <div className="box_wrap shadow-sm bg-white mentor_box">
            <Slider {...Mentors_details}>
              {menteeRequestData &&
                menteeRequestData.map((item, key) => (
                  <Fragment key={key}>
                    {item.status === 4 &&
                      item.is_completed !== 1 &&
                      item.mentee_termination_status !== 2 && (
                        <div className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap">
                          <div className="mentro_details_leftbar">
                            <div className="mentor_pic">
                              <img
                                src={item.mentors_image}
                                alt="find a career mentor online"
                              />
                            </div>
                            <div className="profile_btn mt-3 ">
                              <Link
                                to={{ pathname: item.mentors_linkedin }}
                                className="link_btn btn m-0"
                                target="_blank"
                              >
                                <img
                                  src="/assets/images/linkedin.png"
                                  className="mr-2 bg-white"
                                  alt="career mentoring services"
                                />
                                Linkedin Profile
                              </Link>
                            </div>
                            <div className="extar_info_link">
                              <ul className="px-0 pt-2 mentor_log">
                                <li>
                                  {item?.mf_mental_health == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.mf_mental_health}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={
                                          process.env
                                            .REACT_APP_STATIC_IMAGE_PATH +
                                          "usermind.svg"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                                <li>
                                  {item?.mf_certified_coach == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.mf_certified_coach}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={
                                          process.env
                                            .REACT_APP_STATIC_IMAGE_PATH +
                                          "useraward.svg"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                                <li>
                                  {item?.mentor_lgbtq_discussion == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.LGBTQ_discussion}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={rainbow}
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="mentro_details_sidebar">
                            <div className="row py-2 py-md-4">
                              <Col xl={4}>
                                <div className="d-flex ">
                                  <div className="name_info">
                                    <h4 className="mb-0">{item.mentor_name}</h4>
                                    <h5>
                                      <span className="d-block">
                                        {item.mentors_organization}
                                      </span>
                                    </h5>
                                    <h5>{item.mentors_position}</h5>
                                    <h5>{UCWords(item.mentor_main_area)}</h5>
                                  </div>
                                </div>
                              </Col>
                              <Col xl={8} className="">
                                <div className="current-mentees profile_btn d-sm-flex  justify-content-xl-end ">
                                  {item.mentor_review !== "" ? (
                                    <span
                                      className="link_btn btn btn_red"
                                      onClick={() => {
                                        setModel(true);
                                        setModelData(item);
                                      }}
                                    >
                                      Feedback
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <span
                                    className="link_btn btn btn_red"
                                    onClick={() => handleclickChat(item)}
                                  >
                                    <img
                                      src="/assets/images/msg.png"
                                      alt="msg_icon"
                                      className="mr-2 bg-white"
                                    />
                                    Send Message
                                  </span>

                                  <Link
                                    to=""
                                    className="link_btn btn"
                                    onClick={(e) => {
                                      writeReview(
                                        e,
                                        item.mentee_id,
                                        item.mentor_id,
                                        item.mentee_review
                                      );
                                    }}
                                  >
                                    <img
                                      src="/assets/images/review_blue.png"
                                      alt="review_icon"
                                      className="mr-2 bg-white"
                                    />
                                    {item.mentee_review === "" &&
                                      "Write A Review"}
                                    {item.mentee_review !== "" &&
                                      "Update Review"}
                                  </Link>

                                  {(item.mentee_termination_status === 0 ||
                                    item.mentee_termination_status === "") && (
                                      <>
                                        <Link
                                          to=""
                                          className="link_btn btn"
                                          onClick={(e) => {
                                            endRelationshipModal(
                                              e,
                                              item.mentee_id,
                                              item.mentor_id,
                                              item.mentor_review,
                                              item.id
                                            );
                                          }}
                                        >
                                          <img
                                            src="/assets/images/review_blue.png"
                                            alt="review_icon"
                                            className="mr-2 bg-white"
                                          />
                                          Terminate the Relationship
                                        </Link>
                                        <Link
                                          to=""
                                          className="link_btn btn"
                                          onClick={(e) => {
                                            handleSchedule(e, item);
                                          }}
                                        >
                                          <img
                                            src="/assets/images/review_blue.png"
                                            alt="review_icon"
                                            className="mr-2 bg-white"
                                          />
                                          Schedule Meeting
                                        </Link>
                                      </>
                                    )}
                                  {(item.mentee_termination_status === 1 ||
                                    item.mentee_termination_status === 2) && (
                                      <Link
                                        to=""
                                        className="link_btn btn disabledCursor"
                                        onClick={(event) =>
                                          event.preventDefault()
                                        }
                                      >
                                        <img
                                          src="/assets/images/review_blue.png"
                                          alt="review_icon"
                                          className="mr-2 bg-white"
                                        />
                                        Terminate the Relationship
                                      </Link>
                                    )}
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      style={{ marginTop: "10px" }}
                                      size="sm"
                                      title=""
                                    >
                                      {/* <Dropdown.Header>Options</Dropdown.Header> */}
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          viewReportModal(e, item);
                                        }}
                                      >
                                        Report
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </Col>
                            </div>
                            <ul className="date_list py-2 py-md-4">
                              <li className="d-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/calender.png"
                                    alt="calender_image"
                                  />
                                </div>
                                <div className="date_content pl-2">
                                  <span className="d-block">
                                    Session start date
                                  </span>
                                  <span className="d-block date">
                                    {item.session_start_date}
                                  </span>
                                </div>
                              </li>

                              <li className="d-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/calender.png"
                                    alt="calendericon"
                                  />
                                </div>
                                <div className="date_content pl-2">
                                  <span className="d-block">
                                    Session end date
                                  </span>
                                  <span className="d-block date">
                                    {item.session_end_date}
                                  </span>
                                </div>
                              </li>
                              {item.mentee_resume && (
                                <li className="d-sm-flex align-items-center">
                                  <div className="date_icon">
                                    <img
                                      src="/assets/images/pdf.png"
                                      alt="download-icon"
                                    />
                                  </div>

                                  <div className="date_content pl-2">
                                    <Link
                                      to=""
                                      download
                                      className="d-block date color_red"
                                      onClick={(e) =>
                                        downloadResume(e, item.mentee_resume)
                                      }
                                    >
                                      {"Download Resume"}
                                    </Link>
                                  </div>
                                </li>
                              )}

                              <li className="d-flex align-items-center">
                                &nbsp;
                              </li>
                            </ul>
                            <h3 className="mb-1">About me</h3>
                            <div className="">
                              <p>{item.mentors_about}</p>
                            </div>
                          </div>
                        </div>
                      )}

                    {item.status === 4 &&
                      item.is_completed !== 1 &&
                      item.mentee_termination_status === 2 && (
                        <div
                          className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap"
                          key={key}
                        >
                          <div className="mentro_details_leftbar">
                            <div className="mentor_pic">
                              <img
                                src={item.mentors_image}
                                alt="career mentoring for women"
                              />
                            </div>
                            <div className="profile_btn mt-3">
                              <Link
                                to={{ pathname: item.mentors_linkedin }}
                                className="link_btn btn m-0"
                                target="_blank"
                              >
                                <img
                                  src="/assets/images/linkedin.png"
                                  className="mr-2 bg-white"
                                  alt="career mentoring for women"
                                />
                                Linkedin Profile
                              </Link>
                            </div>
                            <div className="extar_info_link">
                              <ul className="px-0 pt-2 mentor_log">
                                <li>
                                  {item?.mf_mental_health == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.mf_mental_health}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={
                                          process.env
                                            .REACT_APP_STATIC_IMAGE_PATH +
                                          "usermind.svg"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                                <li>
                                  {item?.mf_certified_coach == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.mf_certified_coach}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={
                                          process.env
                                            .REACT_APP_STATIC_IMAGE_PATH +
                                          "useraward.svg"
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                                <li>
                                  {item?.mentor_lgbtq_discussion == 1 && (
                                    <Tooltip
                                      size="4xl"
                                      title={
                                        <h5>{config?.LGBTQ_discussion}</h5>
                                      }
                                      describeChild
                                      arrow
                                    >
                                      <img
                                        src={rainbow}
                                      />
                                    </Tooltip>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="mentro_details_sidebar">
                            <div className="row py-2 py-md-4">
                              <Col xl={4}>
                                <div className="d-flex ">
                                  <div className="name_info">
                                    <h4 className="mb-0">{item.mentor_name}</h4>
                                    <h5>
                                      <span className="d-block">
                                        {item.mentors_organization}
                                      </span>
                                    </h5>
                                    <h5>{item.mentors_position}</h5>
                                    <h5>{UCWords(item.mentor_main_area)}</h5>
                                  </div>
                                </div>
                              </Col>
                              <Col xl={8} className="">
                                <div className="current-mentees profile_btn d-sm-flex  justify-content-xl-end ">
                                  {item.mentor_review !== "" ? (
                                    <span
                                      className="link_btn btn btn_red"
                                      onClick={() => {
                                        setModel(true);
                                        setModelData(item);
                                      }}
                                    >
                                      Feedback
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <Link
                                    to=""
                                    className="link_btn btn"
                                    onClick={(e) => {
                                      writeReview(
                                        e,
                                        item.mentee_id,
                                        item.mentor_id,
                                        item.mentee_review
                                      );
                                    }}
                                  >
                                    <img
                                      src="/assets/images/review_blue.png"
                                      alt="diversity champions for women"
                                      className="mr-2 bg-white"
                                    />
                                    {item.mentee_review === "" &&
                                      "Write A Review"}
                                    {item.mentee_review !== "" &&
                                      "Update Review"}
                                  </Link>
                                  <Link
                                    to=""
                                    className="link_btn btn btn_red"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Completed
                                  </Link>
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu
                                      style={{ marginTop: "10px" }}
                                      size="sm"
                                      title=""
                                    >
                                      {/* <Dropdown.Header>Options</Dropdown.Header> */}
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          viewReportModal(e, item);
                                        }}
                                      >
                                        Report
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  {/* <button
                                    style={{ border: "none" }}
                                    className="link_btn btn"
                                  >
                                    <i
                                      class="fas fa-flag"
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                        fontSize: "20px",
                                        // zIndex: 999,
                                        // position: "absolute",
                                        // top: "10%",
                                        // right: "0",
                                      }}
                                      onClick={(e) => {
                                        viewReportModal(e, item);
                                      }}
                                      title="Report User"
                                    ></i>
                                  </button> */}
                                </div>
                              </Col>
                            </div>
                            <ul className="date_list py-2 py-md-4">
                              <li className="d-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/calender.png"
                                    alt="female mentoring programs"
                                  />
                                </div>
                                <div className="date_content pl-2">
                                  <span className="d-block">
                                    Session start date
                                  </span>
                                  <span className="d-block date">
                                    {item.session_start_date}
                                  </span>
                                </div>
                              </li>

                              <li className="d-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/calender.png"
                                    alt="career consultants for women"
                                  />
                                </div>
                                <div className="date_content pl-2">
                                  <span className="d-block">
                                    Session end date
                                  </span>
                                  <span className="d-block date">
                                    {item.session_end_date}
                                  </span>
                                </div>
                              </li>
                              {item.mentee_resume && (
                                <li className="d-sm-flex align-items-center">
                                  <div className="date_icon">
                                    <img
                                      src="/assets/images/pdf.png"
                                      alt="download-icon"
                                    />
                                  </div>

                                  <div className="date_content pl-2">
                                    <Link
                                      to=""
                                      download
                                      className="d-block date color_red"
                                      onClick={(e) =>
                                        downloadResume(e, item.mentee_resume)
                                      }
                                    >
                                      {"Download Resume"}
                                    </Link>
                                  </div>
                                </li>
                              )}

                              <li className="d-flex align-items-center">
                                &nbsp;
                              </li>
                            </ul>
                            <h3 className="mb-1">About me</h3>
                            <div className="">
                              <p>{item.mentors_about}</p>
                            </div>
                          </div>
                        </div>
                      )}

                    {item.status === 4 && item.is_completed === 1 && (
                      <div
                        className="mentro_details mb-2 mb-md-4 d-flex  flex-wrap"
                        key={key}
                      >
                        <div className="mentro_details_leftbar">
                          <div className="mentor_pic">
                            <img
                              src={item.mentors_image}
                              alt="career consultants for women"
                            />
                          </div>
                          <div className="profile_btn mt-3 ">
                            <Link
                              to={{ pathname: item.mentors_linkedin }}
                              className="link_btn btn m-0"
                              target="_blank"
                            >
                              <img
                                src="/assets/images/linkedin.png"
                                className="mr-2 bg-white"
                                alt="linkedin"
                              />
                              Linkedin Profile
                            </Link>
                          </div>
                          <div className="extar_info_link">
                            <ul className="px-0 pt-2 mentor_log">
                              <li>
                                {item?.mf_mental_health == 1 && (
                                  <Tooltip
                                    size="4xl"
                                    title={<h5>{config?.mf_mental_health}</h5>}
                                    describeChild
                                    arrow
                                  >
                                    <img
                                      src={
                                        process.env
                                          .REACT_APP_STATIC_IMAGE_PATH +
                                        "usermind.svg"
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </li>
                              <li>
                                {item?.mf_certified_coach == 1 && (
                                  <Tooltip
                                    size="4xl"
                                    title={
                                      <h5>{config?.mf_certified_coach}</h5>
                                    }
                                    describeChild
                                    arrow
                                  >
                                    <img
                                      src={
                                        process.env
                                          .REACT_APP_STATIC_IMAGE_PATH +
                                        "useraward.svg"
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </li>
                              <li>
                                {item?.mentor_lgbtq_discussion == 1 && (
                                  <Tooltip
                                    size="4xl"
                                    title={
                                      <h5>{config?.LGBTQ_discussion}</h5>
                                    }
                                    describeChild
                                    arrow
                                  >
                                    <img
                                      src={rainbow}
                                    />
                                  </Tooltip>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mentro_details_sidebar">
                          <div className="row py-2 py-md-4">
                            <Col xl={4}>
                              <div className="d-flex ">
                                <div className="name_info">
                                  <h4 className="mb-0">{item.mentor_name}</h4>
                                  <h5>
                                    <span className="d-block">
                                      {item.mentors_organization}
                                    </span>
                                  </h5>
                                  <h5>{item.mentors_position}</h5>
                                  <h5>{UCWords(item.mentor_main_area)}</h5>
                                </div>
                              </div>
                            </Col>
                            <Col xl={8} className="">
                              <div className="current-mentees profile_btn d-sm-flex  justify-content-xl-end">
                                {item.mentor_review !== "" ? (
                                  <span
                                    className="link_btn btn btn_red"
                                    onClick={() => {
                                      setModel(true);
                                      setModelData(item);
                                    }}
                                  >
                                    Feedback
                                  </span>
                                ) : (
                                  ""
                                )}
                                <span
                                  className="link_btn btn btn_red"
                                  onClick={() => handleclickChat(item)}
                                >
                                  <img
                                    src="/assets/images/msg.png"
                                    alt="msg_icon"
                                    className="mr-2 bg-white"
                                  />
                                  Send Message
                                </span>
                                <Link
                                  to=""
                                  className="link_btn btn"
                                  onClick={(e) => {
                                    writeReview(
                                      e,
                                      item.mentee_id,
                                      item.mentor_id,
                                      item.mentee_review
                                    );
                                  }}
                                >
                                  <img
                                    src="/assets/images/review_blue.png"
                                    alt="review_icon"
                                    className="mr-2 bg-white"
                                  />
                                  {item.mentee_review === "" &&
                                    "Write A Review"}
                                  {item.mentee_review !== "" && "Update Review"}
                                </Link>
                                <Link
                                  to=""
                                  className="link_btn btn btn_red"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Completed
                                </Link>
                                <Dropdown>
                                  <Dropdown.Toggle as={CustomToggle} />
                                  <Dropdown.Menu
                                    style={{ marginTop: "10px" }}
                                    size="sm"
                                    title=""
                                  >
                                    {/* <Dropdown.Header>Options</Dropdown.Header> */}
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        viewReportModal(e, item);
                                      }}
                                    >
                                      Report
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                                {/* <button
                                  style={{ border: "none" }}
                                  className="link_btn btn"
                                >
                                  <i
                                    class="fas fa-flag"
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                    onClick={(e) => {
                                      viewReportModal(e, item);
                                    }}
                                    title="Report User"
                                  ></i>
                                </button> */}
                              </div>
                            </Col>
                          </div>
                          <ul className="date_list py-2 py-md-4">
                            <li className="d-flex align-items-center">
                              <div className="date_icon">
                                <img
                                  src="/assets/images/calender.png"
                                  alt="calender_image"
                                />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block">
                                  Session start date
                                </span>
                                <span className="d-block date">
                                  {item.session_start_date}
                                </span>
                              </div>
                            </li>

                            <li className="d-flex align-items-center">
                              <div className="date_icon">
                                <img
                                  src="/assets/images/calender.png"
                                  alt="calendericon"
                                />
                              </div>
                              <div className="date_content pl-2">
                                <span className="d-block">
                                  Session end date
                                </span>
                                <span className="d-block date">
                                  {item.session_end_date}
                                </span>
                              </div>
                            </li>
                            {item.mentee_resume && (
                              <li className="d-sm-flex align-items-center">
                                <div className="date_icon">
                                  <img
                                    src="/assets/images/pdf.png"
                                    alt="download-icon"
                                  />
                                </div>

                                <div className="date_content pl-2">
                                  <Link
                                    to=""
                                    download
                                    className="d-block date color_red"
                                    onClick={(e) =>
                                      downloadResume(e, item.mentee_resume)
                                    }
                                  >
                                    {"Download Resume"}
                                  </Link>
                                </div>
                              </li>
                            )}

                            <li className="d-flex align-items-center">
                              &nbsp;
                            </li>
                          </ul>
                          <h3 className="mb-1">About me</h3>
                          <div className="">
                            <p>{item.mentors_about}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
            </Slider>
            {(menteeRequestData.length === 0 || pagination?.total == 0) && (
              <div className="text-center">No data found.</div>
            )}
          </div>
        </div>
      </div>
      {modalShow && (
        <Details
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {model && (
        <FeedbackMentor
          show={model}
          userdata={modeldata}
          onHide={() => setModel(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {reportModalShow && (
        <ReportUser
          show={reportModalShow}
          userdata={userData2}
          onHide={() => setReportModalShow(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {toolkitShow && (
        <DToolkit
          show={toolkitShow}
          onHide={() => setToolkitModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {relationModalShow && (
        <Relationship
          show={relationModalShow}
          endrelationship={endRelationship}
          actions={actions}
          onHide={() => setRelationModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
      {scheduleModal && (
        <ScheduleMeetingModal
          show={scheduleModal}
          scheduleUser={scheduleUser}
          onHide={() => setScheduleModal(false)}
          // forumData={() => actions.loadForumData(userInfo.id)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    menteeRequestData: state.menteeRequestData,
    userInfo: state.userInfo,
    isAuth: state.isAuth,
    pagination: state.pagination
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(menteesRequestAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
