import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import * as userActions from "../../actions/userActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showToasterWithOk, showErrorToaster } from "../../utils/helpers";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";

const StudentSignup4 = (props) => {
  const { actions, title } = props;
  const { register, errors, handleSubmit } = useForm();
  const [getLoader, setLoader] = useState(false);

  const [ resumeText, setResumeText ] = useState("Upload resume");

  useEffect(() => {
    document.title = title;
  }, [title]);

  /* Resume upload functionality */
  const [selectedResume, setSelectedResume] = useState("");
  const onChangeResume = (e) => {
    if (e.target.files[0]) {
      var size = parseFloat(e.target.files[0].size / (1024 * 1024)).toFixed(2); 
      
      if (!e.target.files[0].name.match(/\.(pdf)$/)) {
        showErrorToaster('Please select valid file.');
      }
      else if(size > 1) {
        showErrorToaster('Please select resume size less than 1 MB');
      }
      else{
        const selectedResume = e.target.files[0];
        setSelectedResume(selectedResume);
        setResumeText(selectedResume.name);
      }
    }
  };

  const history = useHistory();
  const onSubmit = (data) => {
    const formData = new FormData();
    var params = {};
    params.email = localStorage.getItem("email");
    params.password = localStorage.getItem("password");
    params.role_id = ["Mentor", "Mentees"];
    formData.append("userId", localStorage.getItem("userId"));
    formData.append("about", data.about);
    formData.append("privacy", data.privacy);
    formData.append("terms", data.terms);
    formData.append("stepNumber", 4);
    formData.append("roleId", "Mentees");
    formData.append("role_id", "Mentees");
	  params.avl_resume = '0';
	
    if (selectedResume.name) {
      formData.append("resume", selectedResume, selectedResume.name);
	    params.avl_resume = '1';
    }
    setLoader(true);

    if(data.about){
      agent
      .post(API.SAVEUSERDETAILS, formData)
      .then((res) => {
        
        setLoader(false);
        localStorage.clear('student_img');
        if (res.data.status === 422) {
          showErrorToaster(res.data.message);
        }
        else{
          actions.userLoginData(params);
        }
      })
      .catch((err) => {
        setLoader(false);
        showToasterWithOk(err.message);
      });
    }else{
      setLoader(false);
      alert("please enter about yourself");
    }

    //history.push(`/mentee-signup-step-3`);
  };

  return (
    <div>
      <section className="loginouter studentsteps">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
            <Row className="align-items-center ">
              <Col md={12}>
                <div className="logininnerouter">
                  <div className="w-100">
                    <Link to="/">
                      <img src="/assets/images/logo.svg" alt="logo" />
                    </Link>
                    <div className="loginform">
                      <h1>Tell us about yourself</h1>
                      <div className="steps_signup">
                        <div>
                          <span className="stepcount active">1</span>
                          <span className="stepcount active">2</span>
                          <span className="stepcount active">3</span>
                          <span className="stepcount active">4</span>
                        </div>
                      </div>
                      <div className="profilescreen">
                        <h4 className="text-center">
                          How would you like to be introduced  *
                        </h4>
                        {/* <p className="text-center mt-2">
                          What else you would like your mentors to know
                        </p> */}

                        <div className="whoamform">
                          <Row className="align-items-center">
                            <Col md={12}>
                              <div className="form-group">
                                <Form.Control
                                  as="textarea"
                                  rows="5"
                                  name="about"
                                  placeholder="Tell us little more about yourself (1500 characters)*"
                                  className={
                                    errors.about
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  
                                  aria-invalid={errors.about ? "true" : "false"}
                                  ref={register({
                                    required: "Please enter yourself.",
                                    minLength: 3,
                                    maxLength: 1500,
                                  })}
                                />
                                {errors.about &&
                                  errors.about.type === "required" && (
                                    <label className="customeValidationText text-left">
                                      Please enter about yourself
                                    </label>
                                  )}
                                  {errors.about &&
                              errors.about.type === "minLength" && (
                                <label className="customeValidationText text-left">
                                  Your platform about should contain
                                  atleast 3 characters
                                </label>
                              )}
                                {errors.about &&
                                  errors.about.type === "maxLength" && (
                                    <label className="customeValidationText text-left">
                                      About should not more then 1500 characters
                                    </label>
                                  )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="form-group inputfileupload">
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  placeholder={resumeText}
                                ></input>
                                <span className="uploadfile">
                                  <input
                                    type="file"
                                    accept=".pdf"
                                    name="resume"
                                    onChange={onChangeResume}
                                    ref={register}
                                    title=""
                                  />
                                  Upload
                                </span>
                                <sub class="text-danger pl-2">Please upload only in PDF and upto 1 MB in size</sub>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="form-group termsconditions mt-4">
                                <span className="custom_check">
                                Please click here to agree to{" "}
                                  <Link to="/page/terms-and-conditions" target="_blank">Terms and conditions*</Link>{" "}
                                  &nbsp;{" "}
                                  <input
                                    type="checkbox"
                                    name="terms"
                                    className={`form-check-input ${
                                      errors.terms ? "is-invalid" : ""
                                    }`}
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.terms &&
                                    errors.terms.type === "required" && (
                                      <label className="invalid-feedback text-left">
                                        Please agree on terms & conditions
                                      </label>
                                    )}
                                  <span className="check_indicator">
                                    &nbsp;
                                  </span>
                                </span>
                              </div>
                            </Col>
                           
                            <Col md={12}>
                              <div className="form-group mt-4">
                                <span className="custom_check">
                                Please click here to agree to  <Link to="/page/privacy-policy" target="_blank"> Privacy policy*</Link>{" "}
                                  <input
                                    type="checkbox"
                                    name="privacy"
                                    className={`form-check-input ${
                                      errors.privacy ? "is-invalid" : ""
                                    }`}
                                    ref={register({
                                      required: true,
                                    })}
                                  />
                                  {errors.privacy &&
                                    errors.privacy.type === "required" && (
                                      <label className="invalid-feedback text-left">
                                        Please agree on Privacy Policy
                                      </label>
                                    )}
                                  <span className="check_indicator">
                                    &nbsp;
                                  </span>
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="form-group mt-3 text-center">
                        <Button
                          className="btn loginsubmit nextbtn outline mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/mentee-signup-step-3");
                          }}
                        >
                          Previous
                        </Button>
                        <Button
                          className="btn loginsubmit nextbtn ml-2"
                          type="submit"
                          disabled={getLoader}
                        >
                                  {getLoader ? "Loading..." : "Submit"}
                          
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    superUserParams: state.superUserParams,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentSignup4);
