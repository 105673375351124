import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "../../actions/settingActions";
import * as homePageActions from "../../actions/homePageActions";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import Button from "../../Component/Button";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import Video from "./video";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import { showToasterWithOk } from "../../utils/helpers";
import { useForm } from "react-hook-form";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Subscribe from "../../Component/Subscribe/index";
import playBox from '../../assets/images/playBox.jpg'

const Index = (props) => {
  const {
    actions,
    setting,
    brand,
    homePageData,
    leaders,
    testimonials,
    community,
    isAuth,
    userInfo,
  } = props;
  const [modalShow, setModalShow] = useState(false);
  const { register, errors, handleSubmit, reset } = useForm();
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    reset:reset2,
  } = useForm({});

  const history = useHistory();
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const joinGroupDetail = async (e, community_id, community_slug) => {
    e.preventDefault();
    window.location = "/community-details/" + community_slug;
  };

  useEffect(() => {
    const fetchData = () => {
      actions.getBrandData();
      actions.loadHomePageData("home");
      actions.loadLeaderData(10);
      actions.loadTestimonialsData(10);
      actions.loadCommunityData(10);
    };
    fetchData();
  }, [actions]);

  const [emailValue, setEmailValue] = useState("");

  const changeEmail = (e) => {
    setEmailValue(e.target.value);
  };
  useEffect(() => {
    // window.scrollTo(0, 0);
    document.title = homePageData.meta_title;
  });

  const onSubmitEmail = (data) => {
    agent
      .get(API.checkInvitationEMail + "/" + data.email)
      .then((res) => {
        if (res.data.data === true) {
          actions.sendActivationMail(data);
          reset2('register2')
        }
        if (res.data.data === false) {
          showToasterWithOk(res.data.message);

        }
      })
      .catch((err) => {
        showToasterWithOk(err);
      });
  };

  const onSubmit = (data) => {
    agent
      .get(API.checkInvitationEMail + "/" + data.email)
      .then((res) => {
        if (res.data.data === true) {
          actions.sendActivationMail(data);
        }
        if (res.data.data === false) {
          showToasterWithOk(res.data.message);
        }
      })
      .catch((err) => {
        showToasterWithOk(err);
      });
      reset()
  };

  var leadersSilder = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: false,
          arrows: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  var TestimonialsSilder = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: false,
          arrows: true,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  var communities = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: false,
          arrows: true,
        },
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: true, 
        },
      },
    ],
  };

  var Brand = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          
        },
      },
    ],
  };

  return (
    <>
      <section id="home" className="home_banner_block d-flex">
        <Container>
          <Row className=" align-items-center ">
            <Col md={7} className="order-2 order-md-1">
              <div className="banner_content pt-2 pt-md-0">
                <h1 className="banner_title  text-md-left">
                  Impactful connections <br/>for rising women
                </h1>
                <p>Get access to Mentors, who are global industry leaders.<br/>
                Benefit from domain-specific, focused career guidance.<br/>
                Be an inspiring role model and help grow women’s talent pools.</p>
                

                <div className="caption_Secondary">
                    <strong>Be a vLookUp Mentor or Mentee today.</strong>
                   <Link to="/register" className="btn px-4">Sign Up<span className="ml-2 verticle-middle mt-1"><img src="assets/images/whiteArrow.svg"/></span></Link>
                   </div>
                   <div className="achivment"> 
                        <ul className="achievements_list">
                            <li>
                            
                            <CountUp end={setting.mentors} duration={2}>
                                {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start}>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                                )}
                            </CountUp>
                            <strong>Mentors</strong>
                            </li>

                            <li>
                          
                            <span>
                                <CountUp end={setting.mentees} duration={2}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start}>
                                    <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                                </CountUp>
                                +
                            </span>
                            <strong>Mentees</strong>
                            </li>

                            <li>
                            
                            <CountUp end={setting.organisations} duration={2}>
                                {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start}>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                                )}
                            </CountUp>
                            <strong>Organizations</strong>
                            </li>

                            <li>
                            
                            <CountUp end={setting.countries} duration={2}>
                                {({ countUpRef, start }) => (
                                <VisibilitySensor onChange={start}>
                                    <span ref={countUpRef} />
                                </VisibilitySensor>
                                )}
                            </CountUp>
                            <strong>Countries</strong>
                            </li>

                            <li>
                            
                            <span>
                                <CountUp end={setting.mentoring_hours} duration={2}>
                                {({ countUpRef, start }) => (
                                    <VisibilitySensor onChange={start}>
                                    <span ref={countUpRef} />
                                    </VisibilitySensor>
                                )}
                                </CountUp>
                                +
                            </span>
                            <strong>Mentoring Hours</strong>
                            </li>
                        </ul> 
                    </div> 

                {/* <div className="login_link text-center text-md-left">
                  Already a member?&nbsp;
                  <Link to="/login">Log in here</Link>
                </div> */}
              </div>
            </Col>

            <Col md={5} className="order-1 order-md-2 pb-4 pb-md-0">
              <div className="banner_video ">
                <div className="play_box">
                  <img
                    src={playBox}
                    // src={
                    //   process.env.REACT_APP_STATIC_IMAGE_PATH +
                    //   "circle-cropped.png"
                    // }
                    alt="video"
                  />
                  <span
                    className="btn_play"
                    onClick={() => setModalShow(true)}
                  >
                    <i className="fas fa-play"></i>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section
        className="how_sec"
        dangerouslySetInnerHTML={prepareHtml(homePageData.description)}
      ></section>

      <section className="leader_sec">
        <Container>
          <div className="title text-start pb-0 space-bottom secondaryTitle">
            <Link
              to=""
              className=""
              onClick={(e) => (window.location = "/mentors")}
            >
              Explore our diversity champions
            </Link>
          </div>
          <Slider {...leadersSilder} className="diversity">
            {leaders &&
              leaders.length &&
              leaders.map((item, key) => (
                <div key={key}>
                  <div className="leader_box text-center">
                    <div className="leader_img">
                      <img
                        src={item.image}
                        alt="women's leadership mentoring program"
                      />
                        <span className="leader_title">{item.full_name}</span>
 
                    
                    </div>
                    <div className="leader_info2 text-left">
                        <div>
                          <span className="name">{item.full_name}</span>
                          <span className="profile">{item.position}</span>
                          <span className="experience">
                            {item.organization}
                          </span>
                          <span className="experience">{item.main_area}</span>
                          <div className="LinkedinLink">
                            <Link
                              to={{ pathname: item.linkedin }}
                              className="newcustomelinked text-white m-0"
                              target="_blank"
                            >
                              Linkedin Profile
                            </Link>
                          </div>
                        </div>
                      </div>
                    
                  </div>
                </div>
              ))}
          </Slider>
        </Container>
      </section>
      <section className="communities_sec" id="communities_sec">
        <Container>
          <div className="title text-start pb-0 space-bottom secondaryTitle">
            {"Find your domain community"}
          </div>
          <Slider {...communities} className=" ">
            {community &&
              community.length &&
              community.map((item, key) => (
                <div key={key}>
                  <div className="communities_info">
                    <div>
                      <img
                        src={item.thumb_image}
                        alt="coaching for professional women"
                        className="w-100"
                      />
                      <div className="p-3">
                        <h4 className="m-0 heading pb-2">{item.group_name}</h4>
                        <p className="m-0">{item.title}</p>
                      </div>

                      <div className="mx-1 p-3 border-top d-flex align-items-center">
                        <div className="members_tag">
                           <img src="assets/images/memberTag.svg" width="28px" height="18px"/>
                          {item.member_count}
                          {" Members"}
                        </div>

                        <Link
                          className="btn link_group ml-auto px-4"
                          to=""
                          onClick={(e) => {
                            joinGroupDetail(e, item.id, item.slug);
                          }}
                        >
                          Join Group
                        </Link>

                        {/* <a href="!#" className="">
                          Join Group
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </Container>
      </section>
      <section className="brand_sec">
        <Container>
          <div className="title text-center space-bottom secondaryTitle pb-3 pb-md-5 pr-0">
            Partnering Organisations and Institutions
          </div>
          <Slider {...Brand}>
            {brand &&
              brand.length &&
              brand.map((item, key) => (
                <div key={key}>
                  <div className="brand">
                    <img src={item.image} alt="vlookup" />
                  </div>
                </div>
              ))}
          </Slider>
        </Container>
      </section>
      {/* achievements section */}



      <section className="leader_sec testimonial-slider">
        <Container>
          <div className="title text-start space-bottom position-relative pb-0 secondaryTitle">
            {"What our mentors and mentees are saying"}
          </div>
          <Slider {...TestimonialsSilder}>
            {testimonials &&
              testimonials.length &&
              testimonials.map((item, key) => (
                <div key={key}>
                  <div>
                    <div className="TestimonialsSilderBlock text-center testimonials-slider-div">
                      
                      <div className="TestimonialsSilderWrap"> 
                        <p className="card-text">“ {item.description} ” </p>
                            <div className="testimonialMember">
                            <div className="testimonisalPic">
                            <img
                                className="card-img-top"
                                src={item.image}
                                alt="coaching for professional women"
                            />
                            </div>
                                <h5>
                                {item.full_name} <br />
                                <span>
                                    {" "}
                                    {item.position}, {item.organization}{" "}
                                </span>
                                </h5>
                            </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="leader_box text-center">
                    
                    <div className="">
                          <div className="leader_img testinomials_img"  style={{
                                width: `50px`,
                                height: `50px`,
                              }}>
                            <img src={item.image} alt="vLookup" />
                          </div>
                            <div className="leader_title"   style={{
                              float: `left`,
                            }}> {item.full_name} </div>
                           <span className="leader_title"> {item.position}</span> 
                    </div>
                    
                     <p>{item.description}</p>      
                    
                    <span className="profile">{item.position}</span>
                    <span className="experience">
                      {item.organization}
                    </span> 
                  </div> */}
                </div>
              ))}
          </Slider>
        </Container>
      </section>

      {/* app-promotion */}
      <section className="communities_sec2 download_app_section" id="">
        <Container>
          <Row className="align-items-start">
            <Col lg={8}>
                <div className="d-sm-flex justify-content-between pt-3 pt-md-5">
              <div className="download_app_detail">
                <strong>Download App</strong>
                <p>
                  Be a part of our family <br />& get everything in your pocket
                </p>
                <div className="app_store_link">
                  <a
                    target="_blank"
                    href="https://apps.apple.com/us/app/vlookup-mentoring/id6443502315
"
                  >
                    <img src="../assets/images/appstore.svg" />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.vlookup"
                  >
                    <img src="../assets/images/googleplay.svg" />
                  </a>
                </div>
                </div>

                <div className="scane_qr">
                  <h6>or scan QR code</h6>
                  <div className="d-sm-flex">
                  <div className="qr-wrapper">
                    <span className="qr_scanbox">
                      <img src="../assets/images/iOS-qrcode.png" />
                    </span>
                    <strong>iOS App</strong>
                  </div>
                  <div className="qr-wrapper">
                    {" "}
                    <span className="qr_scanbox">
                      <img src="../assets/images/android-qrcode.png" />
                    </span>
                    <strong>Android App</strong>
                  </div>
                  </div>
                </div>
                </div>
            </Col>

            <Col lg={4}>
              <div className="app_screen">
                <img src="../assets/images/appscreen.png" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="newsletter_sec">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="d-flex align-items-center">
                <div className="newsletter_icon">
                  {" "}
                  <img src="assets/images/subscribe.svg" alt="" />
                </div>
                <div className="newsletter_content pl-1 pl-md-4">
                  {/* <div className="heading">Be a vLookUp mentor or mentee </div> */}
                  <p className="m-0">
                  Subscribe to our newsletter<br/>
to stay updated with vLookUp!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} className="text-end">
              <Form
                onSubmit={handleSubmit2(onSubmitEmail)}
                className="banner_form my-4 mt-md-5 mb-md-4 signup_class ml-auto"
              >
                <Form.Group controlId="formEmail">
                  <FormControl
                    type="email"
                    placeholder="Email Address"
                    className={errors2.email ? "formError" : ""}
                    name="email"
                    aria-invalid={errors2.email ? "true" : "false"}
                    ref={register2({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                      maxLength: 50,
                    })}
                  />
                  {errors2.email && errors2.email.type === "required" && (
                    <span role="alert" className="err_msg">
                      Please enter your email address
                    </span>
                  )}
                  {errors2.email && errors2.email.type === "pattern" && (
                    <span role="alert" className="err_msg">
                      Invalid email address
                    </span>
                  )}
                  {errors2.email && errors2.email.type === "maxLength" && (
                    <span role="alert" className="err_msg">
                      Your email should contain max 50 characters
                    </span>
                  )}{" "}
                  <Button title={"Subscribe"} className={"btn btn-signup "} />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <Subscribe />
      {modalShow && (
        <Video
          show={modalShow}
          setting={setting}
          onHide={() => setModalShow(false)}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    setting: state.setting,
    brand: state.brand,
    leaders: state.leaders,
    testimonials: state.testimonials,
    community: state.community,
    isAuth: state.isAuth,
    homePageData: state.homePageData,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(settingActions, homePageActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
