import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, Route } from "react-router-dom";
import Button from "../../../Component/Button";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../actions/userActions";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import moment from "moment";
import config from "../../../config";
import { getData } from "../../../utils/helpers/localData";

const queryString = require("query-string");
const Index = (props) => {
  var todayDate = new Date();
  todayDate.setMonth(todayDate.getMonth());
  todayDate = todayDate.toLocaleDateString();
  var today = new Date(),
    dateNew =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  var d = new Date();
  d.setMonth(d.getMonth() + 3);
  const nextThreeMonthData = d.toLocaleDateString();
  const location = useLocation();
  const { title, superUserParams, programmes, actions, userExperience } = props;
  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: superUserParams,
  });
  useEffect(() => {
    document.title = "vLookUp - Register Now as Mentor or Mentee";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Register now with vLookUp as a mentor or mentee to join the domain-specific career mentoring between women leaders and experienced industry leaders."
      );
  }, [title]);



  const queryStringParsed = queryString.parse(location.search);
  const isToken = localStorage.getItem("deviceToken");
  const [roleId, setRoleId] = useState([]);
  const [programmesId, setProgrammesId] = useState("");
  const [showClass, setShowClass] = useState("none");
  const [reqclass, setreqclass] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBoxAIshu, setCheckBoxAIshu] = useState();
  const [showDateDiv, setShowDateDiv] = useState("none");
  const [mantees, setMantees] = useState(1);
  const [experience, setExperience] = useState([]);
  const [fromDate, handleFromDateChange] = useState(dateNew);
  const [toDate, handleToDateChange] = useState(nextThreeMonthData);
  const [formObject, setFormObject] = useState(false);
  const [formObject1, setFormObject1] = useState(false);


  const checkFromDate = (date) => {
    handleFromDateChange(moment(new Date(date)).format("YYYY-MM-DD"));
    if (moment(new Date(date)).isValid() === false) {
      setFormObject(true);
    } else if (moment(new Date(date)).isValid()) {
      setFormObject(false);
    }
  };
  const checkToDate = (date) => {
    handleToDateChange(moment(new Date(date)).format("YYYY-MM-DD"));
    if (moment(new Date(date)).isValid() === false) {
      setFormObject1(true);
    } else if (moment(new Date(date)).isValid()) {
      setFormObject1(false);
    }
  };
  const [email, setEmail] = useState(queryStringParsed.email);

  const onSubmit = (data) => {
    console.log(data, 'data')
    if (formObject === true) {
      return false;
    }
    if (formObject1 === true) {
      return false;
    }
    localStorage.setItem("email", data.email);
    localStorage.setItem("password", data.password);
    let params = data;
    let redirectProgram = getData('program')

    params.device_type = "web";
    params.device_token = isToken;
    params.role_id = roleId;
    params.programmes = programmesId;
    params.number_of_mantees = mantees;
    params.availability = available;
    if (params.role == "Mentor") {
      params.mf_mental_health = checkBox ? 1 : 0;
      params.mf_certified_coach = checkBox2 ? 1 : 0;
      params.lgbtq_discussion = data?.lgbtq_discussion ? 1 : 0;
      params.experience_levels = experience;
    } else if (params.role == "Mentees") {
      params.mn_open_to_talk = checkBox ? 1 : 0;
      params.mn_loking_for_coach = checkBox2 ? 1 : 0;
      params.ai_shu_user = checkBoxAIshu ? 1 : 0;
      params.experience_levels = experience;

      if (redirectProgram === 'ignite_women_mentoring') {
        params.ignite_women_mentoring = 1;
      }
      else if(redirectProgram === 'ai_shu_user'){
        params.ai_shu_user = 1;
      }
    }
    actions.userRegisterData(params, roleId);
    // if (roleId === "Mentor") {
    //   history.push(`/mentor-signup-step-1`);
    // } else {
    //   history.push(`/mentee-signup-step-1`);
    // }
  };

  const [available, setAvailable] = useState(1);
  const showDatePicker = (e) => {
    if (e.target.checked === true) {
      setAvailable(2);
      setShowDateDiv("block");
    } else {
      setAvailable(1);
      setShowDateDiv("none");
    }
  };

  const getExperienceData = (userType) => {
    let params = userType;
    actions.getExperienceUser(params);
  };

  const handleCheckboxChange = (event) => {
    if (event?.target?.value === 'all_of_above' && event?.target?.checked) {
      setExperience(userExperience.map((obj) => obj.id))
    }
    else if (!event?.target?.checked && event?.target?.value === 'all_of_above') {
      setExperience([])
    }
    else {
      if (experience.find((obj) => obj === event?.id)) {
        setExperience(experience.filter((item) => item !== event?.id));
      } else {
        setExperience([...experience, event?.id]);
      }
    }

  };

  const handleChange = (item, action) => {
    getExperienceData(item)
    if (item === "Mentor") {
      setShowClass("block");
      setreqclass(true);
    }
    if (item === "Mentees") {
      setShowClass("none");
      setreqclass(false);
    }

    setRoleId(item);
  };
  const handleProgrammesChange = (item, action) => {
    setProgrammesId(item);
  };

  function getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        // const cookieExpires = new Date(cookieValue).toUTCString();
        // const currentDate = new Date().toUTCString();
        //  console.log(currentDate > cookieExpires, 'value', currentDate, cookieExpires)
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }


  useEffect(() => {
    const exampleCookie = getCookie("redirectFrom");
    console.log(exampleCookie)
    setCheckBoxAIshu(exampleCookie === 'ai-shu.in')
    actions.getAllProgrammes();
  }, []);

  console.log(getData('program'))

  return (
    <>
      <section className="loginouter">
        <Container>
          <Row className="align-items-center ">
            <Col md={12}>
              <div className="logininnerouter">
                <div>
                  <div className="loginlogo text-center mb-5">
                    <Link to="/" className="logo m-0">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_IMAGE_PATH + "logo.svg"
                        }
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="loginform">
                    <h1>Sign Up</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={6} sm={6} className="pr-sm-2">
                          <div className="form-group">
                            <Form.Control
                              type="text"
                              placeholder={"First Name*"}
                              className={
                                errors.first_name
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              name="first_name"
                              aria-invalid={
                                errors.first_name ? "true" : "false"
                              }
                              ref={register({
                                required: true,
                                minLength: 2,
                                maxLength: 20,
                              })}
                            />
                            {errors.first_name &&
                              errors.first_name.type === "required" && (
                                <label className="invalid-feedback text-left">
                                  Please enter your first name
                                </label>
                              )}
                            {errors.first_name &&
                              errors.first_name.type === "minLength" && (
                                <label className="invalid-feedback text-left">
                                  Your platform first name should contain
                                  atleast 2 characters
                                </label>
                              )}
                            {errors.first_name &&
                              errors.first_name.type === "maxLength" && (
                                <label className="invalid-feedback text-left">
                                  Your first name should contain max 20
                                  characters
                                </label>
                              )}
                          </div>
                        </Col>
                        <Col md={6} sm={6} className="pl-sm-2">
                          <div className="form-group">
                            <Form.Control
                              type="text"
                              placeholder="Last Name*"
                              className={
                                errors.last_name
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              name="last_name"
                              aria-invalid={errors.last_name ? "true" : "false"}
                              ref={register({
                                required: true,
                                minLength: 2,
                                maxLength: 20,
                              })}
                            />
                            {errors.last_name &&
                              errors.last_name.type === "required" && (
                                <label className="invalid-feedback text-left">
                                  Please enter your last name
                                </label>
                              )}
                            {errors.last_name &&
                              errors.last_name.type === "minLength" && (
                                <label className="invalid-feedback text-left">
                                  Your platform last name should contain atleast
                                  2 characters
                                </label>
                              )}
                            {errors.last_name &&
                              errors.last_name.type === "maxLength" && (
                                <label className="invalid-feedback text-left">
                                  Your last name should contain max 20
                                  characters
                                </label>
                              )}
                          </div>
                        </Col>
                        <Col md={12} sm={12}>
                          <Form.Group controlId="formBasicEmail">
                            <FormControl
                              type="email"
                              placeholder="Email Address*"
                              className={errors.email ? "formError" : ""}
                              name="email"
                              value={email ? email : ""}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              aria-invalid={errors.email ? "true" : "false"}
                              ref={register({
                                required: true,
                                pattern: {
                                  value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                },
                                maxLength: 50,
                              })}
                            />
                            {errors.email &&
                              errors.email.type === "required" && (
                                <span role="alert">
                                  Please enter your email address
                                </span>
                              )}
                            {errors.email &&
                              errors.email.type === "pattern" && (
                                <span role="alert">Invalid email address</span>
                              )}
                            {errors.email &&
                              errors.email.type === "maxLength" && (
                                <span role="alert">
                                  Your email should contain max 50 characters
                                </span>
                              )}
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group controlId="formBasicPassword">
                            <FormControl
                              type="password"
                              className={errors.password ? "formError" : ""}
                              placeholder="Password*"
                              name="password"
                              aria-invalid={errors.password ? "true" : "false"}
                              ref={register({
                                required: true,
                                minLength: 6,
                                pattern: {
                                  value:
                                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                                },
                              })}
                            />
                            {errors.password &&
                              errors.password.type === "required" && (
                                <span role="alert">
                                  Please enter your password
                                </span>
                              )}
                            {errors.password &&
                              errors.password.type === "minLength" && (
                                <span role="alert">
                                  Your password should contain atleast 6
                                  characters
                                </span>
                              )}
                            {errors.password &&
                              errors.password.type === "pattern" && (
                                <span role="alert">
                                  Password must have a minimum of 6 characters.
                                  It must be a combination of numerals, alphabet
                                  and special characters. Special characters
                                  don't include comma, underscores, full stop
                                  etc.
                                </span>
                              )}
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <select
                            name="role"
                            ref={register({
                              required: "select one option",
                            })}
                            className={
                              errors.role
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={(e) => {
                              handleChange(e.target.value);
                            }}
                          >
                            <option value="">Please select role*</option>
                            <option value="Mentor">Mentor</option>
                            <option value="Mentees">Mentee</option>
                          </select>
                          {errors.role && (
                            <label className="invalid-feedback text-left">
                              Please select one option
                            </label>
                          )}
                        </Col>
                        <Col
                          md={12}
                          className="mt-3"
                          style={{ display: showClass }}
                        >
                          <select
                            name="programmes"
                            ref={register({
                              required: reqclass,
                            })}
                            className={
                              errors.programmes
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={(e) => {
                              handleProgrammesChange(e.target.value);
                            }}
                          >
                            <option value="">Please select programmes*</option>
                            {programmes &&
                              programmes.map((value) => (
                                <option value={value.id}>
                                  {value.program}
                                </option>
                              ))}
                          </select>
                          <sub class="text-danger pl-2">
                            Select None if you are not part of a programme
                          </sub>
                          {errors.programmes && (
                            <label className="invalid-feedback text-left">
                              Please select one option
                            </label>
                          )}
                        </Col>
                        <Col
                          md="12"
                          style={{ display: showClass }}
                          className="mt-3"
                        >
                          <Form.Group controlId="formBasicPassword">
                            <Form.Control
                              as="select"
                              name="number_of_mantees"
                              ref={register({
                                required: reqclass,
                              })}
                              className={
                                errors.number_of_mantees
                                  ? "form-control mb-3 is-invalid"
                                  : "form-control mb-3"
                              }
                              onChange={(e) => {
                                setMantees(e.target.value);
                              }}
                            >
                              <option value="">
                                Please select no. of mentees
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </Form.Control>
                            {errors.number_of_mantees && (
                              <label className="invalid-feedback text-left">
                                Please select no. of mentees
                              </label>
                            )}
                          </Form.Group>
                        </Col>
                        {watch("role") === "Mentees" ? (<Col
                          md="12"
                          className="mt-3"
                        >
                          <Form.Group controlId="formBasicPassword">
                            <label>Indicate your working experience level*</label>
                            <Form.Control
                              as="select"
                              name="user_experience"
                              ref={register({
                                required: true,
                              })}
                              className={
                                errors.user_experience
                                  ? "form-control mb-3 is-invalid"
                                  : "form-control mb-3"
                              }
                              onChange={(e) => {
                                setExperience([e.target.value]);
                              }}
                            >
                              <option value="">
                                Please select experience level
                              </option>
                              {userExperience?.map((val, ind) => <option value={val?.id}>{val?.title}</option>)}

                            </Form.Control>
                            {errors.user_experience && (
                              <label className="invalid-feedback text-left">
                                Please select experience level
                              </label>
                            )}
                          </Form.Group>
                        </Col>) : (
                          ""
                        )}


                        {watch("role") === "Mentor" ? (<>
                          <Col
                            md="12"
                            className="mt-3"
                          ><label>Indicate the experience level of mentee you would like to mentor*</label>
                            {userExperience?.map((val, ind) => <span key={ind} className="custom_check mr-2 mb-2">
                              {val?.title}
                              <input
                                type="checkbox"
                                name="experience_levels"
                                checked={experience.find((obj) => obj === val?.id)}
                                value={experience}
                                onChange={(e) =>
                                  handleCheckboxChange(val)
                                }
                                ref={register({
                                  required: true,
                                })}

                              />
                              <span className="check_indicator">&nbsp;</span>
                            </span>)}
                            <span className="custom_check mr-2 mb-2">
                              All of above
                              <input
                                type="checkbox"
                                name="availability"
                                value='all_of_above'
                                checked={experience.length === userExperience.length}
                                onChange={(e) =>
                                  handleCheckboxChange(e)
                                }
                              />
                              <span className="check_indicator">&nbsp;</span>
                            </span>
                            {errors.experience_levels &&
                              errors.experience_levels.type === "required" && (
                                <label className="text-left" style={{ color: '#dc3545', fontSize: '0.875em', marginTop: '0.25rem' }}>
                                  Please select experience level
                                </label>
                              )}
                          </Col>
                        </>) : (
                          ""
                        )}


                        {watch("role") == "Mentees" ? (
                          <>
                            <Col
                              md="12"
                              // style={{ display: showClass }}
                              className="mt-3"
                            >



                              <span className="custom_check mr-2 mb-2">
                                Select, if you are directed to vLookUp via  <a href="https://ai-shu.in" target="_blank" rel="noopener noreferrer" style={{ position: "relative", zIndex: 3 }}>AI-shu.in</a> or if you are part of Olay STEM Mentoring Programme
                                <input
                                  type="checkbox"
                                  name="checkBoxAIshu"
                                  value={checkBoxAIshu}
                                  // className={`form-check-input ${
                                  //   errors.language ? "is-invalid" : ""
                                  // }`}
                                  checked={checkBoxAIshu}
                                  onChange={(e) =>
                                    setCheckBoxAIshu(e.target.checked)
                                  }
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        {watch("role") == "Mentor" ||
                          watch("role") == "Mentees" ? (
                          <>
                            <Col
                              md="12"
                              // style={{ display: showClass }}
                              className="mt-3"
                            >
                              <span style={{ marginBottom: '20px' }}>Indicate your preference for below</span>
                              <span className="custom_check mr-2 mb-2">
                                {watch("role") == "Mentor"
                                  ? config?.mf_mental_health
                                  : config?.mn_open_to_talk}
                                <input
                                  type="checkbox"
                                  name="availability"
                                  value={checkBox}
                                  // className={`form-check-input ${
                                  //   errors.language ? "is-invalid" : ""
                                  // }`}
                                  onChange={(e) =>
                                    setCheckBox(e.target.checked)
                                  }
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                            </Col>
                            <Col
                              md="12"
                              // style={{ display: showClass }}
                              className="mt-3"
                            >
                              <span className="custom_check mr-2 mb-2">
                                {watch("role") == "Mentor"
                                  ? config?.mf_certified_coach
                                  : config?.mn_loking_for_coach}
                                <input
                                  type="checkbox"
                                  name="availability"
                                  value={checkBox2}
                                  // className={`form-check-input ${
                                  //   errors.language ? "is-invalid" : ""
                                  // }`}
                                  onChange={(e) =>
                                    setCheckBox2(e.target.checked)
                                  }
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        {watch("role") === "Mentor" ? (
                          <Col
                            md="12"
                            className="mt-3"
                          >
                            <span className="custom_check mr-2 mb-2">
                              {config?.LGBTQ_discussion}
                              <input
                                type="checkbox"
                                name="lgbtq_discussion"
                                value='lgbtq_discussion'
                                ref={register({
                                  required: false,
                                })}
                                // value={checkBox2}
                                // onChange={(e) =>
                                //   setCheckBox2(e.target.checked)
                                // }
                              />
                              <span className="check_indicator">&nbsp;</span>
                            </span>
                          </Col>
                        ) : null}


                        <Col md={12}>
                          <div className="form-group mt-3">
                            <Button
                              title={"Submit"}
                              className={"btn w-100 loginsubmit"}
                            />
                          </div>
                        </Col>
                        <Col md={12} className="text-center">
                          <Form.Group>
                            Already have an account?&nbsp;
                            <Link to="/login" className="text-danger">
                              Login
                            </Link>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userRegister: state.userRegister,
    programmes: state.programmes,
    userExperience: state.userExperience,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userActions), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
