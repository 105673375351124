let config = {
  defaultPath: "",
  basename: "/",
  LOGIN_PATH: "/login",
  APP_NAME: "Vlookup",
  BLANK_LINK: "#!",
  mf_mental_health:
    "I offer a safe space to talk about non-clinical mental health",
  mf_certified_coach: "I am a certified coach",
  mn_open_to_talk:
    "I am open to talk about non-clinical mental health with my Mentor",
  mn_loking_for_coach: "I am looking for a certified coach",
  LGBTQ_discussion: "I offer a safe space for LGBTQ discussions",
  total_ongoing_mentees: "Total Ongoing Mentees",
  total_completed_mentees: "Total Completed Mentees"
};

export default config;
