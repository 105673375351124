import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import UserHeader from "../../Component/UserHeader";
import UserSidebar from "../../Component/UserSidebar";
import { LOAD_DOMAINS_LIST } from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { useForm } from "react-hook-form";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showToaster } from "../../utils/helpers";
import {
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";

import { LOAD_ORGANIZATION_LIST_SIGNUP } from "../../utils/apiPath";

const UpdateProfile3 = (props) => {
  const { title, actions, updateProfileData, userInfo } = props;
  const { register, errors, handleSubmit } = useForm();
  const [domains, setDomains] = useState([]);

  // Fetching domain list
  const getData = (page = 1) => {
    agent
      .get(LOAD_DOMAINS_LIST, {})
      .then((res) => {
        setDomains(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [objective, setObjective] = useState([]);
  const getObjectiveData = (page = 1) => {
    agent
      .get(LOAD_ORGANIZATION_LIST_SIGNUP + "/"+userInfo.role_id+"/objective", {})
      .then((res) => {
        setObjective(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.title = title;
    getData();
    getObjectiveData();
  }, [title, actions, userInfo]);

  const onSubmit = (data) => {
    const resultArr = mainArea.filter((data1, index) => {
      return mainArea.indexOf(data1) === index;
    });
    if (resultArr.length > 3) {
      showToaster("Sorry,you can not choose more than three main areas.");
      return false;
    }
    data.id = updateProfileData.id;
    data.objective = selectedObjective;
    actions.updateStep4(data, userInfo.id);
    history.push(`/manage-profile`);
  };

  const [selectedObjective, setSelectedObjective] = useState([]);
  useEffect(() => {
    if (updateProfileData) {
      if (updateProfileData.objective) {
        setSelectedObjective(updateProfileData.objective.split(","));
      }
    }
  }, [updateProfileData]);
  const setSelectedObjectiveArray = (e) => {
    const val = e.target.value;
    let cloneSelected1 = [...selectedObjective];

    if (selectedObjective.find((item) => item === val)) {
      cloneSelected1 = selectedObjective.filter((item) => item !== val);
    } else {
      cloneSelected1.push(val);
    }

    setSelectedObjective(cloneSelected1);
  };

  function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

  const [selectedArea, setSelectedArea] = useState([]);
  const [mainArea, setMainArea] = useState([]);
  const setSelectedMainAreaArray = (e, catId) => {
    document.getElementById("mainArea" + catId).checked = true;
    const val = e.target.value;
    let cloneSelected = [...selectedArea];
    if (selectedArea.find((item) => item === val)) {
      cloneSelected = selectedArea.filter((item) => item !== val);
    } else {
      cloneSelected.push(val);
    }
    setSelectedArea(cloneSelected);

    /* SetMainArea keys */
    let cloneMainSelected = [...mainArea];
    if (mainArea.find((item) => item === catId)) {
      // cloneMainSelected = mainArea.filter((item) => item !== catId);
    } else {
      var newValue = catId.toString();
      cloneMainSelected.push(newValue);
    }
    setMainArea(cloneMainSelected);
    /* SetMainArea keys */
  };

  const setSelectedMainCategoryArray = (e) => {
    const val = e.target.value;
    let cloneMainSelected = [...mainArea];
    if (mainArea.find((item) => item === val)) {
      cloneMainSelected = mainArea.filter((item) => item !== val);
    } else {
      cloneMainSelected.push(val);
    }
    setMainArea(cloneMainSelected);
  };
  const history = useHistory();
  return (
    <div>
      <UserHeader />

      <div className="dashboard_outer">
        <UserSidebar />

        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to="#"
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>

          <h2 className="page_heading mb-3">Edit Profile</h2>

          <div className="box shadow bg-white p-md-5">
            <div className="studentformdashboard pt-md-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Col md={12}>
                  <Row className="">
                    <Col sm={12}>
                      <Row>
                        <Col sm={12} className="">
                          {updateProfileData.role_id === "Mentor" && (
                            <h4>
                              Please keep your objectives updated as we use this
                              as a primary criteria to curate your connection
                              with mentees.
                            </h4>
                          )}
                          {updateProfileData.role_id === "Mentees" && (
                            <h4>
                              Please keep your objectives updated as we use this
                              as a primary criteria to curate your connection
                              with mentors.
                            </h4>
                          )}
                          <div className="businessboxouter">
                            <h4 style={{margin:`12px`}}>Objective*</h4>

                            <ul className="bussinessSelect">
                              {objective &&
                                objective.map((item, key) => (
                                  <li key={key}>
                                    <input
                                      type="checkbox"
                                      name="objective"
                                      value={item.value}
                                      className={`form-check-input ${
                                        errors.objective ? "is-invalid" : ""
                                      }`}
                                      checked={
                                        selectedObjective.indexOf(
                                          item.value.toString()
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        setSelectedObjectiveArray(e)
                                      }
                                      ref={register({
                                        required: true,
                                      })}
                                    />

                                    {errors.objective &&
                                      key === 0 &&
                                      errors.objective.type === "required" && (
                                        <label className="invalid-feedback text-left">
                                          Please agree on objective & conditions
                                        </label>
                                      )}

                                    <div className="selectboxouter">
                                      {item.label}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} className="">
                  <Row>
                    <Col md={12}>
                      <div className="form-group mt-3 text-left">
                        <Button
                          className="btn loginsubmit nextbtn outline mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/manage-profile-step3");
                          }}
                        >
                          Previous
                        </Button>
                        <Button
                          className="btn loginsubmit nextbtn ml-2"
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isData: state.isData,
    updateProfileData: state.updateProfileData,
    isLoad: state.isLoad,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile3);
