export const LOGIN = "login";
export const REGISTER = "register";
export const EXPERIENCE = "experience/all";
export const FORGOT_PASSWORD = "forgotPassword";
export const SETTING = "setting";
export const UPDATE_PROFILE = "updateProfile";
export const UPDATE_PASSWORD = "updatePassword";
export const LOAD_EMAIL = "email";
export const LOAD_PAGE = "pages";
export const LOAD_FAQ = "faqs";
export const LOAD_CUSTOMER = "customers";
export const GETCHOOSENMENTORS = "getChoosenMentors";
export const BRAND = "brand";
export const CMS_PAGE = "pages/";
export const FAQ_PAGE = "faq";
export const CONTACT_US_PAGE = "contactUs";
export const LEADER_DATA = "leader";
export const TESTIMONIALS_DATA = "testimonials";
export const PRE_REGISTER = "preRegister";

export const COMMUNITY_DATA = "community";
export const LOAD_COMMUNITY = "communities";
export const GET_FILTER = "filter";
export const SAVEUSERDETAILS = "saveUserDetails";
export const LOAD_DOMAINS_LIST = "getDomainList";
export const LOAD_AREAS_LIST = "getAreasData";
export const SENDACTIVATIONLINK = "sendActivationLink";
export const SAVESUBSCRIBTIONEMAIL = "saveSubscribtionMail";

export const checkInvitationEMail = "checkInvitationEMail";
export const checkLinkedInURL = "checkLinkedInURL";
export const GETUSERDATA = "getUserData";
export const GETUSERPROFILE = "customers/viewCustomerData";
export const LOAD_ORGANIZATION_LIST = "dropdowns/getOrganizationList";
export const LOAD_ORGANIZATION_LIST_SIGNUP =
  "dropdowns/getOrganizationListSignup";

export const LOAD_SUBAREA_LIST = "dropdowns/getsubarealist";

export const FORGOTPASSWORD = "forgotPassword";
export const RESETPASSWORD = "resetPassword";
export const LOAD_MENTORS = "mentors";
export const LOAD_MENTORS_AI_SHU = "mentors-ai-shu";
export const SAVESELECTMENTOR = "saveSelectMentor";
export const SAVEWAITLISTMENTOR = "saveWaitlistMentor";
export const UPDATEUSER = "updateUserData";
export const LOAD_MENTEE_REQUEST = "customers/mentor-request";
export const LOAD_CERTIFICATE = "certificate";
export const LOAD_MENTEE_REQUEST_LOGS = "customers/mentee-request-logs";
export const LOAD_MENTEE_NEW_REQUEST = "customers/mentee-request";
export const ASSIGN_MENTOR_REQUEST = "customers/assign-mentor";
export const LOAD_ACCEPTED_MENTEES_DATA = "customers/accepted_metees_data";
export const LOAD_MY_MENTORS = "customers/my-mentors";
export const PENDING_MENTOR_REQUEST = "customers/pending-mentor-requests";
export const GETSELECTEDMENTORCOUNT = "customers/getSelectedMentorCount";

export const SAVE_RESOURCES_HUB = "SaveResourcesHub";
export const RESOURCES_HUB_DATA = "ResourcesHubData";
export const NEWSLETTER_DATA = "NewsletterData";
export const NEWSLETTER_DETAIL_DATA = "getNewsletterDetails";
export const VIDEO_DATA = "VideoData";

export const LOAD_RESOURCES = "resources";
export const SEND_CHAT_MSG = "sendChatMsg";
export const SEND_REFER = "sendRefer";

// Diagnostic Toolkit
export const GET_OBJ_QUEST = "getObjQuestions";
export const SET_OBJ_QUEST = "setObjQuestions";
export const CHECK_DIAGNOSTIC = "checkDiagnosticToolkit";

// Forum
export const FORUM_CATEGORY = "getForumCategory";
export const FORUM_REPORT_CATEGORY = "getForumReportCategory";
export const FORUM_REPORT_CATEGORY_USER = "getReportUserCategory";
export const SUBMIT_FORUM_QUESTION = "setForumQuestion";
export const FORUM_DATA_FETCH = "getForumQuestions";
export const SUBMIT_FORUM_ANSWER = "setForumAnswer";
export const FORUM_USER_SET = "saveForumUser";
export const FORUM_USER_GET = "getForumUser";
export const FORUM_ANS_LIKE_SET = "setForumAnswerLike";
export const SUBMIT_FORUM_REPORT_ADMIN = "saveReportAdmin";
export const SUBMIT_FORUM_REPORT_USER = "saveReportUser";
// CKEditor Upload
export const CKEDITOR_IMAGE_UPLOAD = "ckeditor_uploader";

export const LOAD_EVENT = "all_events";

export const ABOUTUS_DATA = "aboutus/frontend";
export const SUBMIT_RSVP = "submit_rsvp";
export const EVENT_DETAIL = "event-details";

export const LOAD_PROGRAMMES = "programmes";

export const LOAD_SCHEDULE_LISTING = "certificate";
export const LOAD_CALENDAR_TIMEZONE = "gettimezone";


//export const LOAD_SINDA_SHU = "sinda-lethershine";

export const LOAD_SINDA_SHU = "ignite-women-mentoring"

